import React from 'react';
import { Arrow } from '../components/Arrow';
import { Mockups } from '../components/Mockups';
interface IProject {
  image: string;
  title: string;
  description: string;
  techStack: string[];
  git: string;
  link: string;
}

export const Project = (project: IProject) => {
  return (
    <div className="project">
      <Arrow />
      <div className="content-left">
        <Mockups image={project.image} />
      </div>

      <div className="content-right">
        <h1 className="project-heading">{project.title}</h1>
        <p className="project-description">{project.description}</p>
        <div className="source-links">
          <a href={project.git} target="blank" className="git">
            source code
          </a>
          <a href={project.link} target="blank" className="website">
            website
          </a>
        </div>
        <div className="tech-stack">
          <div className="tech-left-col">
            {project.techStack.map((tech, index) => {
              if (index % 2 !== 0) return <p key={tech}> {tech}</p>;
            })}
          </div>
          <div className="tech-right-col">
            {project.techStack.map((tech, index) => {
              if (index % 2 === 0) return <p key={tech}> {tech}</p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
