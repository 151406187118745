import React, { useState } from 'react';
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';
import { ContactsSlide } from '../components/ContactsSlide';
import { Arrow } from '../components/Arrow';
import { Nav } from '../components/Nav';
export const Main = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageIsVisible, setPageIsVisible] = useState(true);
  const handleVisibilityChange = (isVisible: boolean) => {
    setPageIsVisible(isVisible);
  };
  return (
    <div className="main-page" id="home">
      <Nav />
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-heading">
            Hi, I&apos;m Vlad <br /> and <br /> I am a self-taught <br />
            <span>frontend engineer</span>
          </h1>
          <div className="bottom">
            <a href="#works" className="hero-cta-btn">
              Explore my works
            </a>
            <Arrow />
          </div>
        </div>

        <div className={`hero-contacts ${isOpen ? 'active' : ''}`}>
          <div
            className="btn-box"
            onClick={() => {
              setIsOpen(!isOpen);
            }}>
            <img
              className="hero-circle-part"
              src="/images/hero-contacts-circle.svg"
              alt=""
            />
            <svg
              className="hero-arrow-part"
              width="46"
              height="4"
              viewBox="0 0 46 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.823673 1.82322C0.726044 1.92085 0.726044 2.07915 0.823673 2.17678L2.41467 3.76777C2.51229 3.8654 2.67059 3.8654 2.76822 3.76777C2.86585 3.67014 2.86585 3.51184 2.76822 3.41421L1.354 2L2.76822 0.585786C2.86585 0.488155 2.86585 0.329864 2.76822 0.232233C2.67059 0.134602 2.51229 0.134602 2.41467 0.232233L0.823673 1.82322ZM45.6543 1.75L1.00045 1.75V2.25L45.6543 2.25V1.75Z"
                fill="white"
              />
            </svg>

            <div className="hero-rectangle-part"></div>
          </div>

          <div
            className={`contacts-slide-wrapper ${!isOpen ? 'stealthy' : ''}`}>
            <ContactsSlide isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </div>
      </section>
      <PageVisibility onChange={handleVisibilityChange}>
        {pageIsVisible && (
          <Ticker speed={15} offset="run-in">
            {() => (
              <>
                <p className="ticker-el">My Projects ↓</p>
              </>
            )}
          </Ticker>
        )}
      </PageVisibility>
    </div>
  );
};
