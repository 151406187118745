import React from 'react';

export const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-container">
        <h1>Just a little bit about my skills</h1>
        <div className="about-content">
          <div className="skills-container">
            <div className="skill">
              <h2>Frontend Development</h2>
              <p>
                I build SPAs and PWAs that are fast, modern, and easy to use.
                Back in 2013, my main tech stack was HTML 5, CSS 3, JavaScript,
                and popular libraries like jQuery. Nowadays, I am focusing on
                React + Typescript based development, learning back-end and
                looking to grow in that direction.
              </p>
            </div>
            <div className="divider">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#E22F08" />
              </svg>
            </div>
            <div className="skill">
              {/* <div className="skill-text"> */}
              <h2>UI/UX Design</h2>
              <p>
                I worked on several projects as a project manager in a
                design-focused team which helped me to acquire a good UI/UX eye
                that lets me seamlessly navigate in the complexity of modern web
                user interfaces development.
              </p>
              {/* </div> */}
            </div>
            <div className="divider">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#E22F08" />
              </svg>
            </div>
            <div className="skill">
              <h2>Project Management</h2>
              <p>
                As a frontend engineer, I realized, my previous experience of
                working as a project manager and co-founder of a digital agency
                lets me quickly understand the business needs to develop
                workable long-term solutions.
              </p>
            </div>
          </div>
          <div className="about-photo">
            <img src="./images/about-photo.png" alt="Vlad Nusk photo" />
          </div>
        </div>
      </div>
    </section>
  );
};
