export const projects = [
  {
    title: 'Visas Easy',
    image: 'visaseasy',
    description:
      'Web application that helps people to get a visa to travel to any country in the world. The app allows you to maintain all your visa applications in one place. Use of IATA API helps people to know all requirements for their travel destination. Currently in development.',
    techStack: [
      'Typescript',
      'React.js',
      'Redux Toolkit',
      'Material UI',
      'Django',
      'AWS',
    ],
    git: 'https://github.com/vladnusk/visaseasy-dev',
    link: 'https://visaseasy.com/',
  },
  {
    title: 'Wavetunes Music Player',
    image: 'wavetunes',
    description:
      'Music player that helps you to find and listen music you like. Based on Deezer API and built on React.js',
    techStack: ['React.js', 'Evergreen UI'],
    git: 'https://github.com/vladnusk/WaveTunes-music-player',
    link: 'https://wavetunes.vladnusk.com/',
  },
  {
    title: 'Coolors',
    image: 'coolors',
    description:
      'Generate random colors set, manually adjust HBS parameters, pin colors you like until you get a palette you want, copy HEX codes or save it to the local storage for the future use',
    techStack: ['Javascript', 'HTML5', 'CSS', 'Font Awesome UI'],
    git: 'https://github.com/vladnusk/coolors-app',
    link: 'https://coolors.vladnusk.com/',
  },
  {
    title: 'Beatmaker App',
    image: 'beatmaker',
    description:
      'Beatmaker app allows you to make your own beat with a custom tempo rate and different sounds as a beat base',
    techStack: ['Javascript', 'HTML5', 'CSS'],
    git: 'https://github.com/vladnusk/beatmaker-app',
    link: 'https://beatmaker.vladnusk.com/',
  },
];
