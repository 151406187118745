import React from 'react';
import 'html5-device-mockups/dist/device-mockups.min.css';
import { MacbookPro } from 'react-device-mockups';
import useWindowDimensions from '../utils/windowDimensions';

type Props = {
  image: string;
};

export const Mockups = ({ image }: Props) => {
  const { width } = useWindowDimensions();
  // const laptopWidth = () => {
  //   if (width > 2016) return 1000;
  //   if (width < 2016 && width > 1480) return 800;
  //   if (width < 1480 && width > 968) return 650;
  //   if (width < 968 && width > 600) return 500;
  //   if (width < 600) return 350;
  // };
  const laptopWidth = () => {
    if (width <= 768) return width * 0.8;
    return width / 2.2;
  };
  return (
    <div className="mockups">
      <div className="laptop">
        <MacbookPro width={laptopWidth()}>
          <img
            className="project-image"
            src={`./images/projects/${image}.jpg`}
            alt="project"
          />
        </MacbookPro>
      </div>
    </div>
  );
};
