import React, { Dispatch, SetStateAction, useState } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ContactsSlide = ({ isOpen, setIsOpen }: Props) => {
  const [phone, setPhone] = useState('+1(929) 250 4666');
  const [email, setEmail] = useState('hello@vladnusk.com');
  return (
    <div className="contacts-slide">
      <img
        className="close-btn"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        src="/images/close-btn-slide.svg"
        alt=""
      />
      <h2>Please feel free to contact me</h2>
      <div
        onClick={() => {
          navigator.clipboard.writeText('hello@vladnusk.com');
          setEmail('Email copied to clipboard');
          setTimeout(() => {
            setEmail('hello@vladnusk.com');
          }, 4000);
        }}
        className="email-slide">
        {email}
      </div>
      <div
        onClick={() => {
          navigator.clipboard.writeText('+19292504666');
          setPhone('Phone copied to clipboard');
          setTimeout(() => {
            setPhone('+1(929) 250 4666');
          }, 4000);
        }}
        className="phone-slide">
        {phone}
      </div>
    </div>
  );
};
