import './styles/App.scss';
import { Main } from './pages/Main';
import { Project } from './pages/Project';
import { projects } from './data/projects';
import { About } from './pages/About';
import { Footer } from './pages/Footer';

function App() {
  return (
    <div className="App">
      <Main />
      <div id="works">
        {projects.map((project) => {
          return <Project key={project.title} {...project} />;
        })}
      </div>
      <About />

      <Footer />
    </div>
  );
}

export default App;
