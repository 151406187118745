export const Arrow = () => {
  return (
    <div>
      <svg
        className="mobile-arrow"
        width="12"
        height="42"
        viewBox="0 0 12 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.46967 41.5303C5.76256 41.8232 6.23744 41.8232 6.53033 41.5303L11.3033 36.7574C11.5962 36.4645 11.5962 35.9896 11.3033 35.6967C11.0104 35.4038 10.5355 35.4038 10.2426 35.6967L6 39.9393L1.75736 35.6967C1.46447 35.4038 0.989592 35.4038 0.696699 35.6967C0.403806 35.9896 0.403806 36.4645 0.696699 36.7574L5.46967 41.5303ZM5.25 0L5.25 41H6.75L6.75 0L5.25 0Z"
          fill="#DF4F2F"
        />
      </svg>
    </div>
  );
};
